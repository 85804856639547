<template>
    <div class="policy-details">
        <div class="title">
            <div class="name Text-ellipsis-2">{{ policy_name }}</div>
            <div class="message">
                <div class="message-item">
                    <image src="/static/icon/location.png" class="time" mode="aspectFill" lazy-load="true" />
                    <span>来源：{{ source }}</span>
                </div>
                <div class="message-item">
                    <image src="/static/icon/time.png" class="time" mode="aspectFill" lazy-load="true" />
                    <span>{{ create_at_str }}</span>
                </div>
            </div>
            <div class="message">
                <div class="message-item">
                    <image src="/static/icon/location1.png" class="time" mode="aspectFill" lazy-load="true" />
                    <span>地区：{{ pro ? pro + '>' : '' }}{{ city ? city + '>' : '' }}{{ area }}</span>
                </div>
            </div>
            <div class="message">
                <div class="message-item">
                    <image src="/static/icon/type1.png" class="time" mode="aspectFill" lazy-load="true" />
                    <span>类别：{{ policy_type_name }}</span>
                </div>

                <div class="message-item">
                    <div class="colect-box" :class="{ act: is_collect }" @click="collectToggle">
                        <image class="icon-1" src="../../static/icon/colect.png?ver=12.212" mode="aspectFill"></image>
                        <image class="icon-2" src="../../static/icon/colect-act.png" mode="aspectFill"></image>
                        <div class="text">
                            {{ is_collect ? '已收藏' : '收藏' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table">
            <div class="table-auto">
                <div @click="handleChange(0)" :class="['item', tableIndex ? '' : 'itemActive']">详情</div>
                <div @click="handleChange(1)" :class="['item', tableIndex ? 'itemActive' : '']">政策简读</div>
            </div>
        </div>
        <detail :content="detail" :files="appendix_list" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            imglink: this.imglink,
            navHeight: '0px',
            config: {
                back: true,
                title: '',
                color: '#000000',
                backgroundColor: [1, ['#ffffff', '#ffffff', '#ffffff']],
                centerIconFlag: false, // true 有图标 点击有效果  false 无图标 点击无效果
            },
            tableIndex: 0,
            id: '',
            policy_name: '',
            source: '',
            pro: '',
            city: '',
            area: '',
            view_count: '',
            create_at_str: '',
            appendix_list: [],
            simple_re: '',
            policy_type_name: '',
            content: '',
            detail: '',
            is_collect: false,
            from: false
        }
    },
    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
        }
        if (this.$route.query.from) {
            this.from = true
        }
    },
    mounted() {

    },
    methods: {

    },

}
</script>

<style lang="less" scoped>
html {
    font-size: 7.5vw;
}
</style>